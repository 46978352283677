import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Masonry from '../components/Masonry';
import Card from '../components/Card';
import Headline from '../components/Headline';
import Skills from '../components/Skills';
import '../components/Skills/devicons/devicon.min.css';
import Publications from '../components/Publications';
import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import heroImage from '../images/hero.png';

import './index.css';
import WebGLBackground from '../components/WebGLBackground';

const VerticalAligner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
`;

const Nav = styled.div`
  position: absolute;
  z-index: 2;
  top: 0;
  width: 100%;
`;

const NavContainer = styled.div`
  padding: 30px;
  margin: 0px auto;
  text-align: right;
  max-width: 1200px;
`;

const Bottom = styled.div`
  position: absolute;
  bottom: 30px;
  text-align: center;
  width: 100%;
  z-index: 9001;
`;

const BottomCard = styled.div`
  text-align: center;
  max-width: 800px;
  margin: 20px auto 20px auto;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  padding: 10px;
  /*background-color: rgba(0, 19, 53, 0.5);*/
`;

const LastName = styled.h1`
  margin: 0;
  font-family: 'Kill The Noise', sans-serif;
  color: #f9e89c; /*#4CDDC9;*/
  -webkit-text-fill-color: #f9e89c; 
  -webkit-text-stroke-width: 2.5px;
  -webkit-text-stroke-color: #8632E6;
  font-size: 6.0em;
`;

const FirstName = styled.h1`
  margin: 0;
  font-family: 'Kill The Noise', sans-serif;
  color: #f9e89c; /*#4CDDC9;*/
  -webkit-text-fill-color: #f9e89c; 
  -webkit-text-stroke-width: 2.5px;
  -webkit-text-stroke-color: #8632E6; /*9532e6*/
  font-size: 6.0em;
  margin-bottom: -55px;
`;

const SkewY = styled.div`
  transform: skewY(-5deg);
`;


const SubHeader = styled.h4`
  margin: 0;
  font-family: 'Michroma', sans-serif;
  font-weight: 400;
  margin-bottom: 30px;
  margin-top: 15px; 
  color: #dfdee1;    
`;

const IconDiv = styled.div`
  text-align: center;
  margin-top: 10px;
  > a {
    margin: 0 10px;
  }
`;

export default () => {
  return (
  <>
    <SEO title='Welcome!' />
    <Navbar />
    <WebGLBackground>
      <VerticalAligner>
        <div>
        <SkewY>
          <img src={heroImage} style={{border:'0px', margin: '0px'}} />
        </SkewY>
        </div>
        {
          //<SubHeader> Creative Developer </SubHeader>
        }
      </VerticalAligner>
    </WebGLBackground>
    <Bottom>
      <BottomCard>
        Hello world! I'm a
          <span style={ {color: '#E72C59', fontWeight: 700} }> creative developer </span>
        based in D.C. I am passionate about data visualization, AI, web development and design. 
        <IconDiv>
          <a href="https://github.com/ericgossett">
            <i className="devicon-github-plain" />
          </a>
          <a href="https://www.linkedin.com/in/eric-gossett-654b33183/">
            <i className="devicon-linkedin-plain" />
          </a>
        </IconDiv>
      </BottomCard>
    </Bottom>
  </>
);
};

/*
export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            image {
              childImageSharp {
                sizes(maxWidth: 630) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    }
  }
`;
*/
